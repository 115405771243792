<template>
    <b-row class="match-height">
        <b-col md="4" lg="3" class="mb-50">
            <b-card v-if="card" title="Self Evaluation" :sub-title="card.engagement" class="text-center"
                border-variant="dark">
                <!-- Header -->
                <template #header>
                    <span>
                        {{ timeAgo(card.createdAt) }}
                    </span>
                    <b-badge :variant="card.isSubmitted ? 'success' : 'warning'"
                        :class="card.isSubmitted ? 'badge-glow' : 'badge'">
                        {{ card.isSubmitted ? 'Submitted' : 'Pending' }}
                    </b-badge>
                </template>
                <b-card-text>
                    {{ getBodyText(card) }}
                </b-card-text>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
                    :to="{ name: 'survey-fill', params: { id: card._id } }">
                    {{ getButtonText(card) }}
                </b-button>

                <!-- Footer -->
                <template  v-if="!card.isSubmitted" #footer>
                    <b-progress variant="primary" class="progress-bar-primary" height="1.0rem" show-progress>
                        <b-progress-bar :value="card.progress">
                            <span><strong>{{ card.progress }}%</strong></span>
                        </b-progress-bar>
                    </b-progress>
                </template>
            </b-card>
            <b-card v-else class="position-static py-1">
                <blockquote class="blockquote mb-0">
                    <p>No Self Evaluation available for you at the moment.</p>
                    <footer class="blockquote-footer">
                        <span class="text-secondary h5">
                            Please check back later.
                            <!-- <cite title="Source Title">Source Title</cite> -->
                        </span>
                    </footer>
                </blockquote>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BButton, BProgress, BProgressBar, BBadge } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { timeAgo } from '../../utils';

export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BButton,
        BProgress,
        BProgressBar,
        BBadge,
    },
    directives: {
        Ripple,
    },
    props: {
        surveyType: {
            type: String,
            required: true
        },
        card: {
            required: true,
        },
    },
    methods: {
        getButtonText(card) {
            if (card.progress === 0) {
                return "Start Evaluation";
            } else if (!card.isSubmitted && card.progress > 0) {
                return "Continue Evaluation";
            } else if (card.isSubmitted) {
                return "Edit Evaluation";
            }
            return "Start Evaluation";
        },
        getBodyText(card) {
            return `Complete your self-evaluation survey.`;
        },
        timeAgo, // Directly use the imported timeAgo function
    },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
