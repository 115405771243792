<template>
  <span>
    <b-row v-if="cards && cards.length" class="match-height">
      <b-col v-for="(card, index) in cards" :key="index" md="4" lg="3" class="mb-2">
        <b-card :title="getCardTitle(card)" :sub-title="card.engagement" class="text-center" border-variant="dark">
          <template #header>
            <span>
              {{ timeAgo(card.createdAt) }}
            </span>
            <b-badge :variant="card.isSubmitted ? 'success' : 'warning'" :class="card.isSubmitted ? 'badge-glow'
              : 'badge'">
              {{ card.isSubmitted ? 'Submitted' : 'Pending' }}
            </b-badge>
          </template>
          <b-card-text>
            {{ getBodyText(card) }}
          </b-card-text>
          <template v-if="getButtonText(card)" #footer>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success"
              :to="{ name: 'survey-view', params: { id: card._id } }">
              {{ getButtonText(card) }}
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-else class="position-static p-1">
      <blockquote class="blockquote mb-0">
        <p>None of your advisees have any surveys to fill at this moment.</p>
        <footer class="blockquote-footer">
          <span class="text-secondary h5">
            Please check back later.
            <!-- <cite title="Source Title">Source Title</cite> -->
          </span>
        </footer>
      </blockquote>
    </b-card>
  </span>

</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BImg, BLink, BButton, BProgress, BProgressBar, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';
import { timeAgo } from '../../utils';

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BProgress,
    BProgressBar,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    cards: {
      required: true
    },
  },
  methods: {
    getCardTitle(card) {
      const prefix = card.type === "eval360" ? 'Peer' : card.type === "projectEval" ? "End of Project" : "Self"
      return `${prefix} Review for ${card.reviewee.fullName}`
    },
    getButtonText(card) {
      if (card.isSubmitted) {
        return "View Evaluation";
      } else {
        return null;
      }
    },
    getBodyText(card) {

      const text = `Performance review feedback from ${card.reviewer.fullName}.`
      return text
    },
    timeAgo,
  }
}
</script>