<template>
  <b-overlay v-if="!cardClosed" variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75"
    rounded="sm">
    <b-card ref="bCard" v-bind="cardAttrs" no-body :aria-expanded="content_visible ? 'true' : 'false'"
      :aria-controls="parentID" :style="cardStyles" v-on="$listeners">
      <div v-if="showHeader" class="card-header" @click="handleHeaderClick">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title>
            {{ $attrs.title }}
            <b-badge v-if="notifCount" variant="light-danger">
              {{ notifCount }}
            </b-badge>
          </b-card-title>
          <b-card-sub-title v-if="$attrs['sub-title']">
            {{ $attrs['sub-title'] }}
          </b-card-sub-title>
        </div>
        <span class="d-flex align-items-center prevent-collapse tooltip-wrapper" v-b-tooltip.hover.top="tooltipText">
          <!-- MODAL HANDLING -->
          <b-button id="tooltip-button" v-if="nominateButton" :disabled="nominateButtonDisabled" class="mr-4 pl-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="$emit('nominate')" pill>
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-center">Nominate</span>
          </b-button>
          <!-- END MODAL HANDLING -->

          <!-- Card Actions -->
          <b-card-actions-container v-if="showActions" :available-actions="availableActions"
            :is-collapsed="!content_visible" @collapse="triggerCollapse" @refresh="triggerRefresh" @close="triggerClose"
            @click.stop />
        </span>
      </div>

      <!-- Collapsible Content -->
      <b-collapse :id="parentID" v-model="content_visible" class="card-content">
        <!-- Handle no-body -->
        <slot v-if="$attrs['no-body'] !== undefined" />
        <b-card-body v-else>
          <slot />
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCollapse,
  BOverlay,
  BBadge,
  BButton,
  VBModal,
  VBTooltip
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActionsContainer from './BCardActionsContainer.vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCardActionsContainer,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCollapse,
    BOverlay,
    BBadge,
    BButton,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  inheritAttrs: false,
  props: {
    nominateButton: {
      type: Boolean,
      required: false,
    },
    nominateButtonDisabled: {
      type: Boolean,
      required: false,
    },
    selfEvalSubmitted: {
      type: Boolean,
      required: false,
    },
    notifCount: {
      type: Number,
      required: false,
      default: 0,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    actionCollapse: {
      type: Boolean,
      default: false,
    },
    actionRefresh: {
      type: Boolean,
      default: false,
    },
    actionClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parentID: String(Math.floor(Math.random() * 10) + 1),
      content_visible: !this.collapsed,
      showLoading: false,
      cardClosed: false,
      cardStyles: {},
    };
  },
  computed: {
    cardAttrs() {
      const cardAttrs = { ...this.$attrs };
      delete cardAttrs.title;
      delete cardAttrs['sub-title'];
      return cardAttrs;
    },
    showHeader() {
      return this.$attrs.title || this.$attrs['sub-title'] || !this.noActions;
    },
    showActions() {
      return !this.noActions;
    },
    availableActions() {
      const actions = [];
      if (this.actionCollapse || this.actionRefresh || this.actionClose) {
        if (this.actionCollapse) actions.push('collapse');
        if (this.actionRefresh) actions.push('refresh');
        if (this.actionClose) actions.push('close');
      } else {
        actions.push('collapse', 'refresh', 'close');
      }
      return actions;
    },
    tooltipText() {
      // this.$root.$emit('bv::show::tooltip', 'tooltip-button')
      if (this.nominateButtonDisabled) {
        if (this.selfEvalSubmitted) {
          return 'No survey available for your level, contact an administrator.'
        } else {
          return 'You cannot nominate before completing your Self Evaluation survey.'
        }
      } else {
        return ''
      }
    },
  },
  methods: {
    handleHeaderClick(event) {
      if (!event.target.closest('.prevent-collapse')) {
        this.triggerCollapse();
      }
    },
    removeCard() {
      this.$set(this.cardStyles, 'maxHeight', `${this.$refs.bCard.clientHeight}px`);
      setTimeout(() => {
        this.$set(this.cardStyles, 'maxHeight', '0px');
        this.$set(this.cardStyles, 'overflow', 'hidden');
      }, 10);
    },
    triggerCollapse() {
      this.content_visible = !this.content_visible;
      this.$emit('collapse');
    },
    triggerRefresh() {
      this.showLoading = true;
      this.$emit('refresh');
      setTimeout(() => {
        this.showLoading = false;
      }, 1000);
    },
    triggerClose() {
      this.removeCard();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

// .tooltip-wrapper {
//   display: inline-block; /* display: block works as well */
//   margin: 50px; /* make some space so the tooltip is visible */
// }

// .tooltip-wrapper .btn[disabled] {
//   /* don't let button block mouse events from reaching wrapper */
//   pointer-events: none;
// }

// .tooltip-wrapper.disabled {
//   /* OPTIONAL pointer-events setting above blocks cursor setting, so set it here */
//   cursor: not-allowed;
// }</style>
