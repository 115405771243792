<template>
    <span>
        <b-card no-body class="card-developer-meetup">
            <b-card-header class="d-flex justify-content-center">
                <h1>No Review Cycle </h1>
            </b-card-header>
            <b-card-body class="d-flex justify-content-center my-2">
                <!-- metting header -->
                <!--/ metting header -->

                <b-card-text class="lead">
                    There is no review cycle ongoing at the moment, please check back later.
                </b-card-text>
                <br>

            </b-card-body>
        </b-card>
    </span>
</template>

<script>
import {
    BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BRow, BCol, BButton, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
    components: {
        BCard,
        BImg,
        BCardBody,
        BCardText,
        BCardTitle,
        BMedia,
        BMediaAside,
        BAvatar,
        BAvatarGroup,
        BMediaBody,
        BRow,
        BCol,
        BButton,
        VueApexCharts,
        BCardHeader,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            goalOverviewRadialBar: {
                chart: {
                    height: 245,
                    type: 'radialBar',
                    sparkline: {
                        enabled: true,
                    },
                    dropShadow: {
                        enabled: true,
                        blur: 3,
                        left: 1,
                        top: 1,
                        opacity: 0.1,
                    },
                },
                colors: [$goalStrokeColor2],
                plotOptions: {
                    radialBar: {
                        offsetY: 0, // -10
                        startAngle: -150,
                        endAngle: 150,
                        hollow: {
                            size: '77%',
                        },
                        track: {
                            background: $strokeColor,
                            strokeWidth: '50%',
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                color: $textHeadingColor,
                                fontSize: '2rem',
                                fontWeight: '600',
                            },
                        },
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: [$themeColors.success],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                    },
                },
                stroke: {
                    lineCap: 'round',
                },
                grid: {
                    padding: {
                        bottom: 10,
                    },
                },
            },
        }
    }
}
</script>