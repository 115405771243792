<template>
  <span>
    <span v-if="!loading">
      <div v-if="isCycleActive">
        <!-- If user department is external -->
        <b-tabs v-if="userData.level.department.toLowerCase() === 'external'">
          <!-- Advisees Surveys TAB -->
          <b-tab title="Requested Surveys">
            <app-collapse>
              <client-cards :cards="surveys.incoming" />
            </app-collapse>
          </b-tab>
        </b-tabs>

        <!-- If user level is anything else -->
        <b-tabs v-else>
          <!-- Self Evaluation -->
          <b-tab title="Self Evaluation">
            <self-eval-card class="mt-2" surveyType="selfEval" :card="surveys.selfEval" />
          </b-tab>

          <!-- My Outgoing Requests TAB  -->
          <b-tab active title="My Requests">
            <app-collapse>
              <survey-collapse-item-refresh title="Peer Evaluation" :notifCount="getNotifCount(surveys.outgoing.eval360)"
                :nominateButton="true"
                :nominateButtonDisabled="!(canNominateState.eval360 && surveys.selfEval && surveys.selfEval.isSubmitted)"
                :selfEvalSubmitted="surveys.selfEval && surveys.selfEval.isSubmitted" @nominate="handleNominate"
                @refresh="refreshSurveys('outgoing', 'eval360')">
                <outgoing-cards surveyType="eval360" :cards="surveys.outgoing.eval360" />
              </survey-collapse-item-refresh>
              <survey-collapse-item-refresh title="End of Project Evaluation"
                :notifCount="getNotifCount(surveys.outgoing.projectEval)" :nominateButton="true"
                :nominateButtonDisabled="!(canNominateState.projectEval && surveys.selfEval && surveys.selfEval.isSubmitted)"
                :selfEvalSubmitted="surveys.selfEval && surveys.selfEval.isSubmitted" @nominate="handleNominate"
                @refresh="refreshSurveys('outgoing', 'projectEval')">
                <outgoing-cards surveyType="projectEval" :cards="surveys.outgoing.projectEval" />
              </survey-collapse-item-refresh>
            </app-collapse>
          </b-tab>

          <!-- Incoming Survey Requests TAB  -->
          <b-tab title="Incoming Requests">
            <app-collapse>
              <survey-collapse-item-refresh title="Peer Evaluation" :notifCount="getNotifCount(surveys.incoming.eval360)"
                @refresh="refreshSurveys('incoming', 'eval360')">
                <incoming-cards surveyType="eval360" :cards="surveys.incoming.eval360" />
              </survey-collapse-item-refresh>
              <survey-collapse-item-refresh title="End of Project Evaluation"
                :notifCount="getNotifCount(surveys.incoming.projectEval)"
                @refresh="refreshSurveys('incoming', 'projectEval')">
                <incoming-cards surveyType="projectEval" :cards="surveys.incoming.projectEval" />
              </survey-collapse-item-refresh>
            </app-collapse>
          </b-tab>

          <!-- Advisees Surveys TAB -->
          <b-tab v-if="hasAdvisees" title="Advisees Surveys">
            <app-collapse>
              <!-- <survey-collapse-item-refresh title="Project Evaluation"
            :notifCount="surveys.outgoing.projectEval.notifCount" :nominateButton="true"
            @nominate="handleNominate"> -->
              <advisees-cards :cards="surveys.advisees" />
              <!-- </survey-collapse-item-refresh> -->
            </app-collapse>
          </b-tab>
        </b-tabs>

        <!-- Nomination Modal -->
        <nominate-modal-vue ref="nominateModal" :surveyType="surveyType"
          @nomination-success="handleNominationSuccess" />
      </div>
      <div v-else>
        <no-cycle-active />
      </div>
    </span>
  </span>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import SurveyCollapseItemRefresh from './components/collapse/SurveyCollapseItemRefresh.vue'
import OutgoingCards from './components/cards/OutgoingCards.vue'
import IncomingCards from './components/cards/IncomingCards.vue'
import AdviseesCards from './components/cards/AdviseesCards.vue'
import SelfEvalCard from './components/cards/SelfEvalCard.vue'
import ClientCards from './components/cards/ClientCards.vue'
import NominateModalVue from './components/modal/NominateModal.vue'
import NoCycleActive from './components/no-cycle-active/Card.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getSurveyTypeFromTitle } from './utils'


export default {
  components: {
    AppCollapse,
    SurveyCollapseItemRefresh,
    NominateModalVue,
    IncomingCards,
    OutgoingCards,
    SelfEvalCard,
    AdviseesCards,
    ClientCards,
    NoCycleActive,
    BTabs,
    BTab,
  },
  setup() {
    const { isNavMenuHidden } = useAppConfig()
    return {
      isNavMenuHidden
    }
  },
  beforeCreate() {
    this.$http.get('/cycles/isActive')
      .then(response => {
        this.isCycleActive = response.data.isActive
        this.loading = false
      })

    // Check if user has Advisees
    this.$http.get('/auth/me/hasAdvisees')
      .then(response => {
        this.hasAdvisees = response.data.hasAdvisees
      })
      .catch(error => console.log(error))

    this.$http.get('/surveys/allMySurveys')
      .then(response => {
        this.surveys = response.data
      })
      .catch(error => console.log(error))
  },
  async created() {
    this.isNavMenuHidden = true
    await this.updateCanNominateStates()
  },
  data() {
    return {
      loading: true,
      isCycleActive: false,
      surveyType: '',
      surveys: {
        selfEval: {},
        outgoing: {
          eval360: [],
          projectEval: []
        },
        incoming: {
          eval360: [],
          projectEval: []
        }
      },
      canNominateState: {
        eval360: false,
        projectEval: false,
      },
      hasAdvisees: false,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    getSurveyTypeFromTitle,
    getNotifCount(surveys) {
      return surveys.filter(obj => obj.isSubmitted === false).length;
    },
    async canNominate(type) {
      const res = await this.$http.get(`/users/canNominate?type=${type}`)
      return res.data.canNominate
    },
    async updateCanNominateStates() {
      this.canNominateState.eval360 = await this.canNominate('eval360')
      this.canNominateState.projectEval = await this.canNominate('projectEval')
    },
    handleNominate(title) {
      this.surveyType = this.getSurveyTypeFromTitle(title)
      this.$refs.nominateModal.show()
    },
    handleNominationSuccess(surveyType) {
      this.refreshSurveys('outgoing', surveyType)
    },
    refreshSurveys(category, type) {
      this.$http.get(`/surveys/${category}?type=${type}`)
        .then(response => {
          // console.log(`Refreshing ${category} ${type} surveys...`);
          this.surveys[`${category}`][`${type}`] = response.data
        })
        .catch(error => console.error(error));
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
