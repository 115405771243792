<template>
  <span>
    <b-row v-if="cards && cards.length" class="match-height">
      <b-col v-for="(card, index) in cards" :key="index" md="4" lg="3" class="mb-50">
        <b-card :title="getCardTitle(card)" :sub-title="card.engagement" class="text-center" border-variant="dark">
          <!-- Header -->
          <template #header>
            <span>
              {{ timeAgo(card.createdAt) }}
            </span>
            <!-- <b-badge variant="success" class="badge-glow">
            Active
          </b-badge> -->
          </template>
          <b-card-text>
            {{ getBodyText(card) }}

          </b-card-text>
          <b-button v-if="getButtonText(card)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
            :to="{ name: 'survey-fill', params: {id: card._id} }">
            {{ getButtonText(card) }}
          </b-button>

          <!-- Footer -->
          <template #footer>
            <b-progress variant="primary" class="progress-bar-primary" height="1.0rem" show-progress>
              <b-progress-bar :value="card.progress">
                <span><strong>{{ card.progress }}%</strong></span>
              </b-progress-bar>
            </b-progress>
          </template>
        </b-card>
      </b-col>

    </b-row>
    <b-card v-else class="position-static">
      <blockquote class="blockquote mb-0">
        <p>No one has nominated you for a {{ surveyTypeToTitle(this.surveyType) }} Evaluation yet.</p>
        <footer class="blockquote-footer">
          <span class="text-secondary h5">
            Please check back later.
            <!-- <cite title="Source Title">Source Title</cite> -->
          </span>
        </footer>
      </blockquote>
    </b-card>
  </span>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BImg, BLink, BButton, BProgress, BProgressBar, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';
import { timeAgo } from '../../utils';

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BProgress,
    BProgressBar,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    surveyType: {
      type: String,
      required: true
    },
    cards: {
      type: Array,
      required: true
    }
  },
  methods: {
    surveyTypeToTitle(surveyType) {
      if (surveyType === 'eval360') return "Peer"
      if (surveyType === 'projectEval') return "End of Project"
      return ''
    },
    getCardTitle(card) {
      const prefix = this.surveyType === "eval360" ? 'Peer' : this.surveyType === "projectEval" ? "End of Project" : "Self"
      return `${prefix} Review for ${card.reviewee.fullName}`
    },
    getButtonText(card) {
      if (card.progress === 0) {
        return "Start Evaluation";
      } else if (!card.isSubmitted && card.progress > 0) {
        return "Continue Evaluation";
      } else if (card.isSubmitted) {
        return null;
      }
      return "Start Evaluation";
    },
    getBodyText(card) {
      const text = `Provide your feedback for ${card.reviewee.fullName}'s performance review.`
      return text
    },
    timeAgo,
  },
}
</script>
