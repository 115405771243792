f<template>
    <!-- Nominate Modal -->
    <b-modal id="nominate-modal" :title-html="modalTitleHTML" hide-footer :hide-header-close="busy" no-close-on-backdrop
        centered>
        <validation-observer ref="formObserver">
            <b-form @submit.prevent="onSubmit">
                <b-form-group label-size="lg" label="Request Feedback From" label-for="reviewer-select">
                    <validation-provider name="Request Feedback From" rules="required">
                        <template #default="{ errors }">
                            <v-select class="style-chooser" placeholder="Select a Person" id="from-select"
                                v-model="reviewer" :options="users" label="fullName"
                                :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </template>
                    </validation-provider>
                </b-form-group>
                <b-form-group label-size="lg" label="Engagement" label-for="engagement-input">
                    <validation-provider name="Engagement" rules="required">
                        <template #default="{ errors }">
                            <!-- <b-form-group label="Valid State" label-for="input-valid"> -->
                            <b-form-input id="engagement-input" v-model="engagement"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Type a Project or an Engagement" />
                            <!-- </b-form-group> -->
                            <!-- <v-select class="style-chooser" placeholder="Select or Type a Project" v-model="engagement"
                                :options="projects" taggable :state="errors.length > 0 ? false : null" /> -->
                            <small class="text-danger">{{ errors[0] }}</small>
                        </template>
                    </validation-provider>
                </b-form-group>
                <div class="d-flex justify-content-center p-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" ref="submit" type="submit"
                        :disabled="busy">
                        Nominate
                    </b-button>
                </div>
            </b-form>
        </validation-observer>
        <b-overlay :show="busy" :opacity=0.9 no-wrap @shown="onShown" @hidden="onHidden">
            <template v-slot:overlay>
                <div v-if="!processing" ref="dialog" tabindex="-1" role="dialog" aria-modal="false"
                    aria-labelledby="form-confirm-label" class="text-center p-0">
                    <p class="h3 mb-50"><strong id="form-confirm-label">Are you sure?</strong></p>
                    <p v-if="reviewer" class="mb-2 text-summary">You are nominating <b>{{ reviewer.fullName }}</b> for a
                        <b>{{
                            surveyName
                        }}</b> evaluation
                        <b>({{ engagement }})</b>.
                    </p>
                    <div class="text-center">
                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mr-3"
                            @click="onCancel">
                            Cancel
                        </b-button>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" @click="onOK">
                            OK
                        </b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
    </b-modal>

</template>

<script>
import { BModal, BForm, BButton, BOverlay, BFormGroup, BFormInput, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import { error } from 'loglevel';

extend('required', {
    ...required,
    message: 'This field is required'
});

export default {
    components: {
        BModal,
        BForm,
        BButton,
        BOverlay,
        BFormGroup,
        BFormInput,
        vSelect,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    props: {
        surveyType: {
            type: String,
            default: ''
        }
    },
    created() {
        this.$http.get('/users/names')
            .then(response => {
                this.users = response.data.filter((u) => { return (u.fullName.toLowerCase() != 'admin') })
            })
            .catch(error => console.log(error))
    },
    computed: {
        surveyName() {
            const nameMapping = {
                "selfEval": "Self",
                "eval360": "Peer",
                "projectEval": "End of Project"
            }
            return nameMapping[this.surveyType]
        },
        // modalTitle() {
        //     return `${this.surveyName} Survey Nomination`
        // },
        modalTitleHTML() {
            return `<h3><b>${this.surveyName}</b> Survey Nomination</h3>`
        },
    },
    data() {
        return {
            reviewer: null,
            engagement: null,
            users: [],
            // projects: [],
            processing: false,
            busy: false,
            counter: 1,
            interval: null,
            required,
        }
    },
    methods: {
        show() {
            this.$bvModal.show('nominate-modal')
        },
        hide() {
            this.$bvModal.hide('nominate-modal')
        },
        resetModal() {
            this.reviewer = null
            this.engagement = null
        },
        clearInterval() {
            if (this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
        },
        onShown() {
            this.$refs.dialog.focus()
        },
        onHidden() {
            this.$refs.submit.focus()
        },
        async onSubmit() {
            const isValid = await this.$refs.formObserver.validate()
            if (!isValid) {
                return
            }
            this.processing = false
            this.busy = true
        },
        onCancel() {
            this.busy = false
        },
        onOK() {
            const data = {
                reviewer: this.reviewer._id,
                type: this.surveyType,
                engagement: this.engagement,
            }
            this.processing = true

            this.$http.post(`/surveys`, data)
                .then(response => {
                    this.$nextTick(() => {
                        this.busy = this.processing = false;
                    });
                    this.hide();
                    this.success();
                    this.resetModal();
                    this.$emit('nomination-success', this.surveyType);
                })
                .catch(error => {
                    const errorMessage = (error.response && error.response.data && error.response.data.error) || 'An unknown error occurred';
                    console.error('Error:', errorMessage);
                    this.error(errorMessage);
                    this.$nextTick(() => {
                        this.busy = this.processing = false;
                    });
                    this.hide();
                    this.resetModal();
                });

        },
        success() {
            this.$swal({
                title: `${this.surveyName} Nomination Sent!`,
                text: `${this.reviewer.fullName} was successfully nominated.`,
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        error(errorMessage) {
            this.$swal({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    /* background: #dfe5fb;
    border: none; */
    color: #b7b7b9;
    /* text-transform: lowercase;
    font-variant: small-caps; */
}

/* .style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: #394066;
} */

.text-summary {
    color: rgb(66, 66, 66);
    width: 140%;
    margin: 0 -20%;
    padding: 10px;
}
</style>